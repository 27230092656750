import { useCallback, useSearchParams } from 'components/lib/react-npm'
import { debounce } from 'components/lib/npm';
import { objectToQueryString, queryParamsToObject } from 'utils';

function CustomSearch({
    type = "text",
    placeholder = 'Search',
    className = 'searchBox',
    onChangeHandler
}) {

    const [searchParams, setSearchParams] = useSearchParams()
    const search = searchParams.get('search')

    const debouncedSearch = useCallback(
        debounce((value) => {
            onChangeHandler(value);
        }, 1000)
        , []);

    const handleInputChange = (event) => {
        const { value } = event.target;
        
        const search = objectToQueryString({ search: value, page: '1' })

        const req = queryParamsToObject(!!searchParams.toString() ? (searchParams.toString() + '&' + search) : search)

        setSearchParams(objectToQueryString(req))

        debouncedSearch(value);
    };

    // onRemove Event 
    // () => handleInputChange({target : { value : '}})

    return (
        <input
            type={type}
            placeholder={placeholder}
            className={className}
            value={search ?? ''}
            onChange={handleInputChange}
            autoComplete='off'
        />
    )
}

export default CustomSearch