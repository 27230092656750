import roles from "static/roles";

import { audiencesIcon, dashboardIcon, filmsIcon, revenueIcon, scheduleIcon, locationIcon } from "assets/utils/images";

const { ADMIN } = roles;

export const SIDE_MENU_LIST = [
  {
    title: 'Films',
    value: 'films',
    path: '/films',
    icon: filmsIcon,
    allowedRoles: [ADMIN],
    layout: 'mainRoute',
    subMenu: [
      {
        title: 'Film Details',
        value: 'filmDetails',
        path: "/films/film-details",
        icon: filmsIcon,
        allowedRoles: [ADMIN],
        isRequird: true,
        layout: 'subRoute'
      },
      {
        title: 'Cover Art',
        value: 'coverArt',
        path: '/films/cover-art',
        icon: filmsIcon,
        allowedRoles: [ADMIN],
        isRequird: true,
        layout: 'subRoute'
      },
      {
        title: 'Trailer',
        value: 'trailer',
        path: "/films/trailer",
        icon: filmsIcon,
        allowedRoles: [ADMIN],
        isRequird: true,
        layout: 'subRoute'
      },
      {
        title: 'Cast & Crew',
        value: 'cast&Crew',
        path: '/films/cast-and-crew',
        icon: filmsIcon,
        allowedRoles: [ADMIN],
        isRequird: true,
        layout: 'subRoute'
      },
      {
        title: 'Screening Schedule',
        value: 'screeningSchedule',
        path: "/films/screening-schedule",
        icon: filmsIcon,
        allowedRoles: [ADMIN],
        isRequird: true,
        layout: 'subRoute'
      },
      {
        title: 'Videos',
        value: 'videos',
        path: "/films/videos",
        icon: filmsIcon,
        allowedRoles: [ADMIN],
        layout: 'subRoute'
      },
      {
        title: 'Articles',
        value: 'articles',
        path: "/films/articles",
        icon: filmsIcon,
        allowedRoles: [ADMIN],
        layout: 'subRoute'
      },
      {
        title: 'Photos',
        value: 'photos',
        path: "/films/photos",
        icon: filmsIcon,
        allowedRoles: [ADMIN],
        layout: 'subRoute'
      },
      // {
      //   title: 'Reviews',
      //   value: 'reviews',
      //   path: "/films/reviews",
      //   icon: filmsIcon,
      //   allowedRoles: [ADMIN],
      //   layout: 'subRoute'
      // },
      {
        title: 'Events',
        value: 'events',
        path: "/films/events",
        icon: filmsIcon,
        allowedRoles: [ADMIN],
        layout: 'subRoute'
      },
      // {
      //   title: 'Social Media',
      //   value: 'socialMedia',
      //   path: "/films/social-media",
      //   icon: filmsIcon,
      //   allowedRoles: [ADMIN],
      //   layout: 'subRoute'
      // },
    ]
  },
  {
    title: 'Venues',
    value: 'venues',
    path: '/venues',
    icon: locationIcon,
    allowedRoles: [ADMIN],
    layout: 'mainRoute',
    subData: []
  },
  {
    title: 'Users',
    value: 'users',
    path: '/users',
    icon: audiencesIcon,
    allowedRoles: [ADMIN],
    layout: 'mainRoute',
    subData: []
  },
  {
    title: 'Change Password',
    value: 'ChangePassword',
    path: '/change-password',
    allowedRoles: []
  }
] 

export const SUM_MENU_ALLOWED_LIST = [
  { key: 'filmDetails', value: false },
  { key: 'coverArt', value: false },
  { key: 'trailer', value: false },
  { key: 'cast&Crew', value: false },
  { key: 'screeningSchedule', value: false },
  { key: 'videos', value: false },
  { key: 'articles', value: false },
  { key: 'photos', value: false },
  { key: 'events', value: false },
]