

import { create } from 'components/lib/npm'
import browserStorageService from 'services/shared/browserStorage.service'

const initialState = {
  auth: browserStorageService.getCookie('auth'),
}

const useAuthenticationStore = create((set, get) => ({

  ...initialState,
  setState: (nextState) => set(() => ({ ...nextState })),
  getState: () => get(),

}))

export default useAuthenticationStore