

import { create } from 'components/lib/npm'
import { SIDE_MENU_LIST, SUM_MENU_ALLOWED_LIST } from 'components/ui/sidebar/sideMenuData'

const initialState = {
  activeTab: 'films',
  sideMenu: SIDE_MENU_LIST,
  subSideMenu: SUM_MENU_ALLOWED_LIST,
  isLogOut: false
}

const useCommonStore = create((set, get) => ({

  ...initialState,
  setState: (nextState) => set(() => ({ ...nextState })),
  getState: () => get(),

}))

export default useCommonStore