import { useNavigate, useLocation, Link, useEffect, } from 'components/lib/react-npm'
import { WebLogo, closeIcon } from 'assets/utils/images'
import { ReactComponent as ProfileIcon } from "assets/images/svg/profile_icon.svg";
import { ReactComponent as SettingsIcon } from "assets/images/svg/setting_icon.svg";
import { ReactComponent as CheckmarkIcon } from "assets/images/svg/checkmark_icon.svg";
import { SIDE_MENU_LIST } from './sideMenuData';
import useAuthenticationStore from 'store/authentication/useAuthenticationStore';
import roles from 'static/roles';
import CustomImage from '../custom-image/CustomImage'
import useCommonStore from 'store/common-store/useCommonStore';
import { Select, SelectItem } from "@nextui-org/react";
import { ReactComponent as LockIcon } from "assets/images/svg/lock_icon.svg";
import { ReactComponent as LogoutIcon } from "assets/images/svg/logout_icon.svg";

const SideMenu = () => {

  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { auth } = useAuthenticationStore();

  const { activeTab, sideMenu, subSideMenu, setState } = useCommonStore()

  const pathArray = pathname.split('/');

  useEffect(() => {

    if (pathArray?.length >= 3) {
      const { subMenu } = sideMenu?.find(({ path }) => path.includes(pathArray[1]))
      subMenu && setState({ sideMenu: subMenu })
    } else {
      setState({ sideMenu: [...SIDE_MENU_LIST] })
    }

  }, [pathname])

  const pageChanger = (activeTab) => {
    setState({ activeTab })
  }

  const exitSubMenu = () => {
    navigate(`/${activeTab}`)
    // setState({ sideMenu: [...SIDE_MENU_LIST] })
  }

  const handleChangePassword = () => {
    navigate(`/change-password`)
    // setState({ sideMenu: [...SIDE_MENU_LIST] })
  }

  const handleLogOut = () => {
    setState({ isLogOut: true })
  }

  return (
    <div className='left_wrapper'>
      <div className="sidebar">
        <div className='website_logo'>
          {
            pathArray.length <= 2
              ? <button onClick={() => navigate('/films')}><CustomImage src={WebLogo} alt='WebLogo' className='' /></button>
              : <button className='exit_btn items-center' onClick={exitSubMenu}><div className='close_icon'><CustomImage src={closeIcon} alt='closeIcon' className='' /></div>Exit</button>
          }
        </div>
        <div className='sidebar_links'>
          <div className='sidebar_links_items mt_24'>

            {
              sideMenu.map(({ path, allowedRoles, ...rest }) => (
                allowedRoles.includes(auth?.role ?? roles.USER)
                  ? <SideMenuItem key={path} path={path} pathname={pathname} pageChanger={pageChanger} activeTab={activeTab} subSideMenu={subSideMenu} {...rest} />
                  : null
              ))
            }

          </div>

          <div className='sidebar_footer_links '>
            <label className='footer_link_label'>Profile</label>
            <p className="flex items-center" onClick={() => handleChangePassword()}>
              <LockIcon />
              Change password
            </p>
            <p className="flex items-center danger" onClick={() => handleLogOut()}>
              <LogoutIcon />
              Log Out
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SideMenu


const SideMenuItem = ({ path, pathname, title, value, icon: Icon, pageChanger, layout, isRequird, subSideMenu }) => {

  const navigate = useNavigate();
  const { search } = useLocation()

  const queryParams = new URLSearchParams(search);

  const filmId = queryParams.get('filmId');
  const isEdit = queryParams.get('isEdit');

  const isCheck = subSideMenu.find((element) => element.key === value)
  const isFilmDetailsFill = subSideMenu.find((element) => element.key === 'filmDetails')

  const handleNavigate = (path) => {

    const isPathValid = isFilmDetailsFill.value
    let isPath = '';

    if (layout === 'subRoute') {

      if (!isPathValid) return

      if (!!filmId && !!isEdit) {
        isPath = `${path}?filmId=${filmId}&isEdit=true`;
      } else if (!!filmId) {
        isPath = `${path}?filmId=${filmId}`;
      } else {
        isPath = path;
      }

    } else {
      isPath = path;
    }
    
    navigate(isPath)

  }

  switch (layout) {
    case 'subRoute':
      return (
        <p className={`sidebar_inner_links d_flex ${pathname.includes(path) ? ' active' : ''}`} onClick={() => handleNavigate(path)} >
          {isCheck.value ? <CheckmarkIcon /> : ''}
          {title}
          {isRequird ? <span className='ml_auto'>*</span> : ''}
        </p>
      )

    default:
      return (
        <p className={pathname.includes(path) ? 'active' : ''} onClick={(() => { pageChanger(value); handleNavigate(path); })} >
          <CustomImage src={Icon} alt={title} className='' />
          {title}
        </p>
      )

  }
}

