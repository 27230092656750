import Loader from "components/ui/loader/Loader";
import { useEffect, memo, useRef, Link, useLocation } from "components/lib/react-npm";


const ErrorBoundryFallback = ({ error, resetErrorBoundary }) => {

    const location = useLocation();
    const errorLocation = useRef(location.pathname);

    const chunkFailedMessage = /Loading chunk [\d]+ failed/;

    const refreshPage = () => window.location.reload();

    useEffect(() => {
        if (error?.message && chunkFailedMessage.test(error.message)) {
            window.location.reload()
        }
    }, [error]);

    useEffect(() => {
        if (location.pathname !== errorLocation.current) {
            resetErrorBoundary();
        }
    }, [location.pathname])

    return (error?.message && chunkFailedMessage.test(error.message) ?

        <Loader /> :

        <div className='unauthoriz_page unexcepted_error'>
            <div className='unautho_content'>
                <h2>An Unexpected Application Error!</h2>
                <p className='content'>We're sorry, but our application encountered an unexpected error. Our team has been notified, and we're working diligently to resolve the issue.</p>
                <p className="try_again">In the meantime, here are a few things you can try:</p>
                <div className='d_flex_center unauth_btn gap_24'>
                    <Link to='/' className='blue_border_btn'>Go Back to Home</Link>
                    <Link to='/' className='blue_border_btn' onClick={refreshPage}>Reload Page</Link>
                </div>
            </div>
        </div>
    )


};


export default memo(ErrorBoundryFallback)
