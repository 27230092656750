import LogOutAlert from 'components/popup/log-out-alert/LogOutAlert'
import React from 'react'

export default function ModalProvider({ children }) {
    return (
        <>
            {children}
            <LogOutAlert />
        </>
    )
}
