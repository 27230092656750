export default {
    app: {
      name: 'Joltspace-CMS',
      envType: process.env.REACT_APP_CUSTOM_VARIABLE,
      apiURL: `${process.env.REACT_APP_END_POINT}`,
      clientURL: process.env.REACT_APP_CLIENT_URL,
      cloudFrontURL: `${process.env.REACT_APP_CLOUD_FRONT_URL}`,
    },
    port: process.env.PORT || 3000,
    secretKey : process.env.REACT_APP_SECRET_KEY
};