import { CryptoJS, Cookies } from 'components/lib/npm'
import keys from 'config/keys'
import { expireTime } from 'utils';

const cookies = new Cookies();

class BrowserStorageService {

  // ! ----------⬇ sessionStorage Section ⬇----------

  setSession(key, value) {
    const data = value === undefined ? "" : this.encryption(value);
    window?.sessionStorage.setItem(key, data);
  }

  getSession(key) {
    const data = window?.sessionStorage.getItem(key);
    if (data) {
      return this.decryption(data);
    } else {
      return null;
    }
  }

  removeSession(key) {
    window?.sessionStorage.removeItem(key);
  }

  removeAllSessions() {
    for (const key in window?.sessionStorage) {
      if (window?.sessionStorage.hasOwnProperty(key)) {
        this.removeSession(key);
      }
    }
  }

  // ! ----------⬇ localStorage Section ⬇----------

  setLocal(key, value) {
    const data = value === undefined ? "" : this.encryption(value);
    window?.localStorage.setItem(key, data);
  }

  getLocal(key) {
    try {
      const data = window?.localStorage.getItem(key);
      if (data) {
        return this.decryption(data);
      } else {
        return null;
      }

    } catch (error) {
      return null;
    }
  }

  removeLocal(key) {
    window?.localStorage.removeItem(key);
  }

  removeAllLocals() {
    for (const key in window?.localStorage) {
      if (window?.localStorage.hasOwnProperty(key)) {
        this.removeLocal(key);
      }
    }
  }

  // ! ----------⬇ cookie Section ⬇----------

  setCookie = (key, data, expiry) => {
    if (key && data) {
      let ciphertext = this.encryption(data);

      cookies.set(key, ciphertext, { path: '/', expires: expiry ?? expireTime() });
    }
  };

  getCookie = (key) => {
    const data = cookies.get(key);
    let decryptedData = data ? this.decryption(data) : null;
    return decryptedData;
  };

  removeCookie = (key) => {
    if (key) {
      cookies.remove(key, { path: '/', expires: expireTime() });
    }
  }




  // TODO: Encrypt Data
  encryption = (data) => {
    let ciphertext = keys.app.envType === 'local_value'
      ? data // JSON.stringify(data)
      : CryptoJS.AES.encrypt(JSON.stringify(data), keys.secretKey).toString();
    return ciphertext;
  };

  // TODO: Decrypt Data
  decryption = (data) => {
    let decryptedData = null;
    if (keys.app.envType === 'local_value') {
      decryptedData = data //JSON.parse(data)
    } else {
      let bytes = CryptoJS.AES.decrypt(data, keys.secretKey);
      decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    }
    return decryptedData;
  };

}
export default new BrowserStorageService();