
import { memo, Suspense, Navigate, Outlet, useLocation } from "components/lib/react-npm";
import { ErrorBoundary, helment } from 'components/lib/npm';
import TopLoader from "components/ui/top-loader/TopLoader";
import Loader from "components/ui/loader/Loader";
import ErrorBoundryFallback from "components/error-boundry-fallback/ErrorBoundryFallback";
import useAuthenticationStore from "store/authentication/useAuthenticationStore";

const { HelmetProvider } = helment;

const PublicRouter = ({ title }) => {

    const { auth } = useAuthenticationStore();
    const location = useLocation();
    const rootPath = "/films";

    return auth
        ? <Navigate to={rootPath} state={{ from: location }} replace />
        : (
            <HelmetProvider context={{}}>
                <title>{title}</title>
                <ErrorBoundary FallbackComponent={ErrorBoundryFallback}>
                    <TopLoader>
                        {/* <Layout> */}
                        <Suspense fallback={<Loader />}>
                            <Outlet />
                        </Suspense>
                        {/* </Layout> */}
                    </TopLoader>
                </ErrorBoundary>
            </HelmetProvider>
        )
};

export default memo(PublicRouter);