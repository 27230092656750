import CustomSpinner from "../custom-spinner/CustomSpinner"

function Loader() {
  return (
    <div className="custom-loader-wrapper">
        <CustomSpinner className="custom-loader-secondary" />
    </div>
  )
}

export default Loader