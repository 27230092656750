import RoutesComponent from 'routes/RoutesComponent';
import { NextUIProvider } from "components/lib/next-ui";
import ReactQueryProvider from 'components/providers/ReactQueryProvider';
import ToasterProvider from 'components/providers/ToasterProvider';
import NoInternetConnection from 'components/providers/NoInternetConnection';
import SafeHydrate from 'components/providers/SafeHydrate';
import "react-datepicker/dist/react-datepicker.css";

function App() {

  return (
    <SafeHydrate>
      <NoInternetConnection>
        <ReactQueryProvider>
          <NextUIProvider>
            <ToasterProvider>
              <RoutesComponent />
            </ToasterProvider>
          </NextUIProvider>
        </ReactQueryProvider>
      </NoInternetConnection>
    </SafeHydrate>
  );
}

export default App;
