
// import { imageLoader } from "assets/utils/images.js";
import { LazyLoadImage } from "react-lazy-load-image-component";

export default function CustomImage({ src, alt = 'custom image', placeholderSrc = "", className = "", ...rest }) {
  return (
    <LazyLoadImage
      src={src}
      alt={alt}
      placeholderSrc={placeholderSrc} 
      className={className}
      {...rest}
    />
  );
}
