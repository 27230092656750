import { useState, useRef, useEffect, useLocation } from 'components/lib/react-npm';
import { LoadingBar } from 'components/lib/npm';


function TopLoader({ children }) {

    const [previousLocation, setPreviousLocation] = useState("")
    const { pathname } = useLocation();

    const ref = useRef(null);

    useEffect(() => {

        setPreviousLocation(pathname);

        ref.current.continuousStart()

        if (pathname === previousLocation) {
            setPreviousLocation('');
        }

    }, [pathname])

    useEffect(() => {
        ref.current.complete();
    }, [previousLocation])

    return (
        <>
            <LoadingBar height="5px" color="#77D4E1" ref={ref} shadow={true} />
            {children}
        </>
    )
}

export default TopLoader