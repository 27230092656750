import { useSearchParams } from 'components/lib/react-npm'
import CustomImage from '../custom-image/CustomImage'
import { closeIcon, searchIcon } from 'assets/utils/images'
import CustomButton from '../custom-button/CustomButton'
import CustomSearch from '../custom-search/CustomSearch'
import { queryParamsToObject } from 'utils'

const Header = ({ isDisplaySearch = true, status, submit, backBtn, onChangeHandler }) => {

  // Example 
  const [searchParams, setSearchParams] = useSearchParams()
  const search = searchParams.get('search')
  
  const removeParam = (paramKey) => {
    const params = new URLSearchParams(searchParams);
    // params.delete(paramKey);
    const setParams = queryParamsToObject(params.toString())
    const req = { ...setParams, search : ''}
    setSearchParams(req);
    onChangeHandler('')
  };

  return (
    <header>
      <div className='header_wrapper d_flex align_items_center justify_content_between'>
        <div className='form_group'>
          <div className='custom_form_control'>
            {
              !status ?
                <>
                  {
                    isDisplaySearch && <>
                      <CustomSearch onChangeHandler={onChangeHandler} />
                      <div className='search_icon'><CustomImage src={searchIcon} alt='searchIcon' className='' /></div>
                      {!!search && <div className='close_icon' onClick={() => removeParam('search')}><CustomImage src={closeIcon} alt='closeIcon' className='' /></div>}
                    </>
                  }
                </>
                :
                <div className='status'>
                  <span className={`${status} text_capitalize`}>{status}</span>
                </div>

            }
          </div>
        </div>
        <div className='add_film_btn d_flex'>
          {backBtn && <button className='btn sky_border_btn back_btn' onClick={() => backBtn?.onClick()}>{backBtn?.value}</button>}
          {
            !!submit && (!!submit?.submitForm
              ? <CustomButton form={submit?.submitForm} value={submit?.value} isPending={submit?.isPending ?? false} />
              : <CustomButton value={submit?.value} onClick={submit?.onClick} isPending={submit?.isPending ?? false} />
            )
          }
        </div>

      </div>
    </header>
  )
}

export default Header
