import { Button } from "components/lib/next-ui";
import btn_loader from "assets/images/svg/btn_loader.svg";

function CustomButton({
  type = "submit",
  className = "sky_btn",
  onClick,
  formGroup = '',
  disabled = false,
  isPending = false,
  value = '',
  ...rest
}) {
  return (
    <div className={` form_group  ${formGroup}`}>
      <button
        type={type}
        className={`btn ${className}`}
        onClick={onClick}
        disabled={disabled || isPending}
        {...rest}
      >
        {value}
      </button>
      {/* {isPending && <span className="btn_lord"><img src={btn_loader} alt="btn_loader" /></span>} */}
    </div>
  );
}

export default CustomButton;
