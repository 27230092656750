import { QueryClient, QueryClientProvider, ReactQueryDevtools } from 'components/lib/npm';
import keys from 'config/keys';

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false
        },
    },
});

export default function ReactQueryProvider({ children }) {


    return (
        <QueryClientProvider client={queryClient}>
            {children}
            {keys.app.envType === 'local_value' ? <ReactQueryDevtools initialIsOpen={false} position='right' buttonPosition='bottom-left' /> : null}
        </QueryClientProvider>
    )
}