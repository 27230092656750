


import { lazy } from "components/lib/react-npm";
import roles from "static/roles";

const Authentication = lazy(() => import("pages/authentication/Authentication"));
const LandingPage = lazy(() => import("pages/landing-page/LandingPage"));

const Films = lazy(() => import("pages/films/Films"));
const FilmDetails = lazy(() => import("pages/films/film-details/FilmDetails"));
const Trailer = lazy(() => import("pages/films/trailer/Trailer"));
const CoverArt = lazy(() => import("pages/films/cover-art/CoverArt"));
const FilmsCastAndCrew = lazy(() => import("pages/films/cast-and-crew/CastAndCrew"));
const FilmsAddCastAndCrew = lazy(() => import("pages/films/cast-and-crew/add-cast-and-crew/AddCastAndCrew"));
const ScreeningSchedule = lazy(() => import("pages/films/screening-schedule/ScreeningSchedule"));
const Screening = lazy(() => import("pages/films/screening-schedule/screening/Screening"));
const Articles = lazy(() => import("pages/films/articles/Article"));
const AddArticles = lazy(() => import("pages/films/articles/add-article/AddArticle"));
const Videos = lazy(() => import("pages/films/videos/Videos"));
const Photos = lazy(() => import("pages/films/photos/Photos"));
const Reviews = lazy(() => import("pages/films/reviews/Reviews"));
const Events = lazy(() => import("pages/films/events/Events"));
const Event = lazy(() => import("pages/films/events/event/Event"));
const SocialMedia = lazy(() => import("pages/films/social-media/SocialMedia"));

const Venues = lazy(() => import("pages/venues/Venues"));
const AddVenue = lazy(() => import("pages/venues/add-venue/AddVenue"));

const Users = lazy(() => import("pages/users/Users"));

const ChangePassword = lazy(() => import("pages/my-profile/change -password/ChangePassword"));

const { ADMIN, USER } = roles;
export const privateRouteList = [
    {
        title: "Landing Page",
        path: "/landing-page",
        element: LandingPage,
        allowedRoles: [USER],
    },

    // ! ---------- Films
    {
        title: "Films",
        path: "/films",
        element: Films,
        allowedRoles: [ADMIN],
    },
    {
        title: "Film Details",
        path: "/films/film-details",
        element: FilmDetails,
        allowedRoles: [ADMIN],
    },
    {
        title: "Trailer",
        path: "/films/trailer",
        element: Trailer,
        allowedRoles: [ADMIN],
    },
    {
        title: "Cover Art",
        path: "/films/cover-art",
        element: CoverArt,
        allowedRoles: [ADMIN],
    },
    {
        title: "Cast & Crew",
        path: "/films/cast-and-crew",
        element: FilmsCastAndCrew,
        allowedRoles: [ADMIN],
    },
    {
        title: "Cast & Crew",
        path: "/films/cast-and-crew/add",
        element: FilmsAddCastAndCrew,
        allowedRoles: [ADMIN],
    },
    {
        title: "Screening Schedule",
        path: "/films/screening-schedule",
        element: ScreeningSchedule,
        allowedRoles: [ADMIN],
    },
    {
        title: "Screening",
        path: "/films/screening-schedule/screening",
        element: Screening,
        allowedRoles: [ADMIN],
    },
    {
        title: "Videos",
        path: "/films/videos",
        element: Videos,
        allowedRoles: [ADMIN],
    },
    {
        title: "Articles",
        path: "/films/articles",
        element: Articles,
        allowedRoles: [ADMIN],
    },
    {
        title: "Articles",
        path: "/films/articles/add-articles",
        element: AddArticles,
        allowedRoles: [ADMIN],
    },
    {
        title: "Photos",
        path: "/films/photos",
        element: Photos,
        allowedRoles: [ADMIN],
    },
    {
        title: "Reviews",
        path: "/films/reviews",
        element: Reviews,
        allowedRoles: [ADMIN],
    },
    {
        title: "Events",
        path: "/films/events",
        element: Events,
        allowedRoles: [ADMIN],
    },
    {
        title: "Event",
        path: "/films/events/event",
        element: Event,
        allowedRoles: [ADMIN],
    },
    {
        title: "SocialMedia",
        path: "/films/social-media",
        element: SocialMedia,
        allowedRoles: [ADMIN],
    },

    // ! ---------- Venues
    {
        title: "Venues",
        path: "/venues",
        element: Venues,
        allowedRoles: [ADMIN],
    },
    {
        title: "Venues",
        path: "/venues/add-venue",
        element: AddVenue,
        allowedRoles: [ADMIN],
    },

    // ! ---------- Users
    {
        title: "Users",
        path: "/users",
        element: Users,
        allowedRoles: [ADMIN],
    },

    // ! ---------- My Profile
    {
        title: "Change Password",
        path: "/change-password",
        element: ChangePassword,
        allowedRoles: [ADMIN],
    }
];

export const publicRouteList = [
    {
        title: "Authentication",
        path: '/',
        element: Authentication,
    },
    {
        title: "Log In",
        path: '/login',
        element: Authentication,
    },
];
