
import { DateTime, Duration } from "components/lib/npm";
import CommonMessage from 'static/commonMessage';


export const expireTime = () => new Date(Date.now() + 8 * (60 * 60 * 1000)); // Token expiry time

export function objectToQueryString(obj, removeNulls = false) {
  const keys = Object.keys(obj);

  const keyValuePairs = keys.map(key => {
    if (removeNulls) {
      if (obj[key]) return encodeURIComponent(key) + '=' + encodeURIComponent(obj[key])

    } else {
      return encodeURIComponent(key) + '=' + encodeURIComponent(obj[key]);
    }
  });
  return keyValuePairs.join('&');
}

export function queryParamsToObject(queryString) {
  // Remove leading "?" if present
  queryString = queryString.startsWith('?') ? queryString.slice(1) : queryString;

  // Split the query string into an array of key-value pairs
  const pairs = queryString.split('&');

  // Initialize an empty object to store the key-value pairs
  const result = {};

  // Loop through each key-value pair and add it to the object
  for (const pair of pairs) {
    const [key, value] = pair.split('=');

    // Decode URI components to handle special characters
    const decodedKey = decodeURIComponent(key);
    const decodedValue = decodeURIComponent(value || '');

    // If the key already exists in the object, convert it to an array
    if (result.hasOwnProperty(decodedKey)) {
      // if (Array.isArray(result[decodedKey])) {
      //   result[decodedKey].push(decodedValue);
      // } else {

      // result[decodedKey] = [result[decodedKey], decodedValue];

      result[decodedKey] = decodedValue;
      // }

    } else {
      result[decodedKey] = decodedValue;
    }
  }
  return result;
}

export const fromISO = (date) => DateTime.fromISO(date).setZone(CommonMessage.TimeZonePlace)
export const fromCurrentZone = (date) => DateTime.fromISO(date).setLocale()
export const toISO = (date, formate = 'ccc MMM dd yyyy hh:mm:ss a') => DateTime.fromFormat(date, formate).setZone(CommonMessage.TimeZonePlace).toISO() // .setZone(CommonMessage.TimeZonePlace)
export const getCurrentDate = () => DateTime.now()

export function convertMinute(totalMinutes) { // Input: 99
  const duration = Duration.fromObject({ minutes: totalMinutes });

  const formattedTime = duration.toFormat('h\'h\' m\'m\'');

  return formattedTime // Output: 1h 39m
}

export const formatTime = (timeInSeconds) => {
  const result = new Date(Math.round(timeInSeconds) * 1000)
    .toISOString()
    .substring(11, 19);
  // if duration is over hour
  if (+result.substring(0, 2) > 0) {
    // show 00:00:00
    return result;
  } else {
    // else show 00:00
    return result.substring(3);
  }
};

export const setTimeInDate = (inputDateString, hour = 0, minute = 0, second = 0) => {
  const inputDate = DateTime.fromFormat(inputDateString, "ccc LLL dd yyyy");
  const newDateTime = inputDate.set({ hour, minute }).toFormat('ccc LLL dd yyyy hh:mm:ss a');
  return newDateTime
}


export const scrollTopSmooth = () => window.scrollTo({ top: 0, behavior: 'smooth' });