export default class CommonMessage {

    static logIn = 'login';
    static signUp = 'signup';

    static Success = 'Success';
    static Error = 'Error';
    static Warning = 'Warning';
    static Info = 'Info';
    static Confirm = 'Confirm';
    static Yes = 'Yes';
    static No = 'No';
    static Cancel = 'Cancel'
    static Close = 'Close'
    static SuccessOperation = 'Operation Completed Successfully';
    static ErrorOperation = 'Operation Completed With Error';
    static InfoOperation = 'Info Operation';
    static WarningOperation = 'Operation Completed With Warnings';
    static Loading = 'Loading ...';
    static ServerSideErrorHappended = 'Server Side Error Happended'
    static UnknownErrorHappened = 'Unknown Error Happened';
    static AdressNotFound = 'Adress Not Found';
    static ModelIsNotValid = 'Model Is Not Valid';

    static validImageFormate = 'Please select a valid image format. Only .jpg, .jpeg, and .png are allowed';
    static imageSizeFormate = 'You have exceeded the maximum size of 5MB for your image.';
    
    static TimeZonePlace = 'America/New_York';
}