import { useState, useEffect, memo } from 'components/lib/react-npm';

const NoInternetConnection = ({ children }) => {

    const [isOnline, setOnline] = useState(true);

    useEffect(() => {

        setOnline(navigator.onLine);

        const onlineHandler = () => setOnline(true);
        const offlineHandler = () => setOnline(false);

        window.addEventListener('online', onlineHandler);
        window.addEventListener('offline', offlineHandler);

        return () => {
            window.removeEventListener('online', onlineHandler);
            window.removeEventListener('offline', offlineHandler);
        }
    }, [])

    if (isOnline) return (<>{children}</>);

    else return (
        <div className="i_popup i_requestInfo_popup i_msg_preview i_new_broadcast_message">
            <div className='i_popup_content'>
                <div className='i_popup_header d_flex_between border-bottom'>
                    Internet connection lost
                </div>
            </div>
        </div>
    )

}

export default memo(NoInternetConnection);