import { useEffect } from "components/lib/react-npm";
import { Modal, ModalContent } from "components/lib/next-ui";

function CustomModal({
    children,
    isOpen,
    onOpenChange,
    handleClose,
    radius = 'none',
    placement = 'center',
    size = "5xl",
    backdrop = "blur",
    className = "",
    hideCloseButton = false
}) {

    useEffect(() => {
        const closeOnEscapeKey = (e) => (e?.key === "Escape" ? handleClose() : null);
        document.body.addEventListener("keydown", closeOnEscapeKey);
        return () => {
            document.body.removeEventListener("keydown", closeOnEscapeKey);
        };
    }, [handleClose]);

    return (
        <Modal
            className={className}
            size={size} // "xs", "sm", "md", "lg", "xl", "2xl", "3xl", "4xl", "5xl", "full"
            isOpen={isOpen}
            onOpenChange={onOpenChange}
            onClose={handleClose}
            backdrop={backdrop} //"opaque", "blur", "transparent"
            placement={placement}
            radius={radius} // none | sm | md | lg
            hideCloseButton={hideCloseButton}
        >
            <ModalContent>
                {children}
            </ModalContent>
        </Modal>
    );
}
export default CustomModal;