import Header from "components/ui/header/Header"
import SideMenu from "components/ui/sidebar/SideMenu"


function WrapperLayout({ children }) {
    return (
        <div className="d_flex">

            {/* SideMenu */}
            <SideMenu />

            {/* Sidebar's Content */}
            <div className='right_wrapper'>
                <div className="sidebar_content">
                    {/* Header */}
                    {/* <Header /> */}

                    {/* Page Content */}
                    {/* <div className="right_inner_wrapper">
                        <div className="right_inner_wrapper_content"> */}
                            {children}
                        {/* </div>
                    </div> */}
                </div>
            </div>
        </div>

    )
}

export default WrapperLayout