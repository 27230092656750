import { logoutIcon } from 'assets/utils/images'
import CustomImage from 'components/ui/custom-image/CustomImage'
import CustomModal from 'components/ui/modal/Modal'
import BrowserStorageService from 'services/shared/browserStorage.service';
import useCommonStore from 'store/common-store/useCommonStore'
import { useNavigate } from 'components/lib/react-npm'
import useAuthenticationStore from 'store/authentication/useAuthenticationStore';
import { SIDE_MENU_LIST } from 'components/ui/sidebar/sideMenuData';


const LogOutAlert = () => {

    const navigate = useNavigate()

    const { isLogOut, setState } = useCommonStore()
    const { setState: setAuth } = useAuthenticationStore()

    const handleCancel = () => {
        setState({ isLogOut: false })
    }

    const handleLogOut = () => {
        BrowserStorageService.removeCookie('auth')
        setAuth({ auth: null })
        setState({ sideMenu: [...SIDE_MENU_LIST] })
        handleCancel()
    }

    return (
        <CustomModal isOpen={isLogOut} className='popup'>
            <div className='popup_wrapper'>
                <div className='popup_content'>
                    <h3>Are you sure want to Log Out?</h3>
                </div>
                <CustomImage src={logoutIcon} alt='logoutIcon' className='popup_image m_auto popup_inner_content' />
                <div className='popup_btns d_flex justify_content_center'>
                    <button className='btn sky_border_btn mr_24' onClick={() => handleCancel()}>CANCEL</button>
                    <button className='btn danger_btn' onClick={() => handleLogOut()}>LOG OUT</button>
                </div>
            </div>
        </CustomModal>
    )
}

export default LogOutAlert
